import React, { useState } from "react";
import "./MobileCalendar.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

const MobileCalendar = ({
  onFlightSelection,
  disablePast = false,
  selectedDate,
  disabledFrom,
  numberOfMonths = 2,
  orientation = "vertical",
}) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  const handleDateClick = (date) => {
    onFlightSelection(date);
  };

  const handlePrev = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 2)
    );
  };

  const handleNext = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 2)
    );
  };

  const getDaysInMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    const totalDays = new Date(year, month + 1, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null); // Empty slots before the 1st day
    }
    for (let i = 1; i <= totalDays; i++) {
      days.push(i);
    }
    return days;
  };

  const isBeforeToday = (year, month, day) => {
    const date = new Date(year, month, day);
    if (disablePast) return date < new Date(today.setHours(0, 0, 0, 0));
    else return false;
  };

  const isDepartDate = (year, month, day) => {
    if (!disabledFrom) return false;
    const date = new Date(year, month, day);
    if (disabledFrom && date <= new Date(disabledFrom)) {
      return true;
    }
    return date.toDateString() === new Date(disabledFrom).toDateString();
  };

  const renderMonth = (monthOffset) => {
    const month = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + monthOffset,
      1
    );
    const days = getDaysInMonth(month.getFullYear(), month.getMonth());

    return (
      <div key={monthOffset} className="mobile-calendar-month">
        <div className="mobile-calendar-header">
          <span>
            {month.toLocaleString("default", { month: "long" })}{" "}
            {month.getFullYear()}
          </span>
        </div>
        <div className={`mobile-calendar-grid`}>
          {days.map((day, index) => (
            <div
              key={index}
              className={`mobile-calendar-date ${
                day &&
                (isBeforeToday(month.getFullYear(), month.getMonth(), day) ||
                  isDepartDate(month.getFullYear(), month.getMonth(), day))
                  ? "disabled"
                  : ""
              } ${
                selectedDate &&
                selectedDate.getDate() === day &&
                selectedDate.getMonth() === month.getMonth()
                  ? "selected"
                  : ""
              }`}
              onClick={() => {
                if (
                  day &&
                  (!isBeforeToday(month.getFullYear(), month.getMonth(), day) ||
                    !isDepartDate(month.getFullYear(), month.getMonth(), day))
                ) {
                  handleDateClick(
                    new Date(month.getFullYear(), month.getMonth(), day)
                  );
                }
              }}
            >
              {day || ""}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mobile-calendar-navigation">
        <button onClick={handlePrev}>
          <ChevronLeft />
        </button>
        <button onClick={handleNext}>
          <ChevronRight />
        </button>
      </div>
      <div className={`mobile-calendar-days`}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="mobile-calendar-day-name">
            {day}
          </div>
        ))}
      </div>
      <div
        className={`mobile-calendar-container ${
          orientation === "vertical" ? "vertical" : "horizontal"
        }`}
      >
        {Array.from({ length: numberOfMonths }).map((_, index) =>
          renderMonth(index)
        )}
      </div>
    </>
  );
};

export default MobileCalendar;
