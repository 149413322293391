import React from "react";
import { LandmarkItem } from "./LandmarkItem";
import styles from "./ReviewCard.module.css";
import { CircleParking } from "lucide-react";

const landmarks = [
  { name: "Border Road Organization Camp", distance: "330m" },
  { name: "Tibetan Monastery", distance: "830m" },
  { name: "Aleo", distance: "470m" },
  { name: "Van Vihar National Park", distance: "650m" },
  { name: "Civil Hospital", distance: "700m" },
];

export const ReviewCard = () => {
  return (
    <div className={styles.reviewContainer}>
      <div className={styles.ratingSection}>
        <div className={styles.ratingWrapper}>
          <div className={styles.ratingScore}>4.6</div>
          <div className={styles.ratingText}>Very Good</div>
          <div className={styles.ratingCount}>(4322 ratings)</div>
        </div>
        <div className={styles.reviewLink}>All Reviews</div>
      </div>

      <div className={styles.landmarksContainer}>
        <div className={styles.landmarksContent}>
          <div className={styles.landmarksInfo}>
            <div className={styles.landmarksList}>
              <div className={styles.landmarksTitle}>Closest Landmarks</div>
              {landmarks.map((landmark, index) => (
                <LandmarkItem
                  key={index}
                  name={landmark.name}
                  distance={landmark.distance}
                />
              ))}
              <div className={styles.parkingSection}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <CircleParking />
                  <div style={{ fontSize: "1rem" }}>Parking</div>
                </div>
                <div className={styles.parkingStatus}>FREE</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
