import React, { useEffect } from "react";
import "./UpiOption.css";
import { useSelector } from "react-redux";

const UpiOptions = () => {
  const [totlePrice, setTotlePrice] = React.useState(0);
  const fareDetails = useSelector(
    (state) => state.checkoutJourney.bookingData
  ).FareDetails;
  useEffect(() => {
    if (fareDetails) {
      let totle = Object.entries(fareDetails)
        .filter(([key]) => key !== "Total") // Exclude the "totle" key
        .reduce((sum, [key, value]) => {
          if (typeof value === "object") {
            // If the value is an object, sum its values
            return (
              sum +
              Object.values(value).reduce(
                (innerSum, innerValue) => innerSum + innerValue,
                0
              )
            );
          }
          return sum + value; // Otherwise, add the value directly
        }, 0);
      setTotlePrice(totle);
    }
  });
  return (
    <>
      <div className="payment-container">
        <div className="scan-pay">
          <h3>Scan and pay</h3>
          <img
            src="https://via.placeholder.com/250"
            alt="QR Code"
            className="qr-code"
          />
        </div>
        <div className="divider-upi" />
        <div className="enter-upi">
          <h3>Enter UPI ID</h3>
          <input
            type="text"
            placeholder="Enter UPI ID here"
            className="upi-input"
          />
        </div>
      </div>
      <div className="footer-payment-upi-desk">
        <span>Total: &#8377;{totlePrice}</span>
        <button className="upi-pay-button">VERIFY & PAY</button>
      </div>
    </>
  );
};

export default UpiOptions;
