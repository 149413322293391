import React from "react";
import styles from "./PriceOption.module.css";

export const PriceOption = ({
  priceIndex,
  price,
  fareType,
  onSelectPriceOption,
  selectedPrice,
  flightIndex,
}) => {
  const formatAmount = (amount) => {
    // if (typeof amount === "string") {
    //   return amount;
    // }
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
    return formatter.format(price);
  };
  return (
    <>
      <div className={styles.priceRow}>
        <label
          htmlFor={`option-${priceIndex}_${flightIndex}`}
          className={styles.radioWrapper}
        >
          <input
            type="radio"
            name={`option-${priceIndex}_${flightIndex}`}
            id={`option-${priceIndex}_${flightIndex}`}
            checked={parseInt(selectedPrice) === price}
            className={styles.radioInput}
            value={price}
            onChange={onSelectPriceOption}
          />
          <span className={styles.radioCircle}></span>
          <span className={styles.priceLabel}>{formatAmount(price)}</span>
        </label>
      </div>
      <div className={styles.fareType}>{fareType}</div>
      <div className={styles.divider} />
    </>
  );
};
