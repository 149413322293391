import { Check, ChevronDown } from "lucide-react";
import React from "react";

const CreditDebitOptions = () => {
  return (
    <>
      <div className="mobilePaymentWrapper">
        <div className="mobileHeaderSection">
          <div className="mobileProtectionMessage">
            <div className="mobileSecurityMessage">
              <Check color="green" size={12} />
              <div className="mobileSecurityText">
                We use secure transmission
              </div>
            </div>
            <div className="mobileSecurityMessage">
              <Check color="green" size={12} />
              <div className="mobileSecurityText">
                We Protect your personal information
              </div>
            </div>
          </div>
        </div>
        <label htmlFor="nameOnCard" className="mobileFormLabel">
          Name on Card
        </label>
        <input type="text" id="nameOnCard" className="mobileFormInput" />

        <label htmlFor="cardNumber" className="mobileFormLabel">
          Debit/Credit Card number
        </label>
        <input
          type="number"
          id="cardNumber"
          className="mobileFormInput"
          aria-label="label"
        />

        <label className="mobileFormLabel">Expiry date</label>
        <div className="mobileDateContainer">
          <div className="mobileMonthnyear">
            <div>Month</div>
            <ChevronDown size={24} />
          </div>
          <div className="mobileMonthnyear">
            <div>Year</div>
            <ChevronDown size={24} />
          </div>
        </div>

        <div className="mobileCountry-n-securityWrapper">
          <div className="mobilePaymentCountry">
            <label htmlFor="country" className="mobileFormLabel">
              Billing country/territory
            </label>
            <div className="mobileCountrySelect">
              <div>India</div>
              <ChevronDown size={24} />
            </div>
          </div>
          <div className="mobileSecurityWrapper">
            <label htmlFor="securityCode" className="mobileFormLabel">
              Security Code
            </label>
            <input
              type="number"
              id="securityCode"
              className="mobileSecurityCodeInput"
              aria-label="Security Code"
            />
          </div>
        </div>
      </div>
      <div className="mobileFooter-payment">
        <button className="mobilePay-button">VERIFY & PAY</button>
      </div>
    </>
  );
};

export default CreditDebitOptions;
