import React, { useState } from "react";
import { DateInput } from "./DateInput";
import styles from "./HotelBooking.module.css";
import NavbarNew from "../../../Components/Navbar/NavbarNew";
import useHotelSearch from "../../../Hooks/useHotelSearch";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Locate } from "lucide-react";
import MobileCalendar from "../../../Components/Flights/[mobileRes]/flightHeader/FlightSearchPopups/MobileCalendar";

export function HotelMobileSearch() {
  const [openCalendar, setOpenCalendar] = useState(null);
  const [guestCount, setGuestCount] = useState(0);
  const [roomCount, setRoomCount] = useState(0);

  const increment = (setter) => {
    setter((prevCount) => prevCount + 1);
  };

  const decrement = (setter) => {
    setter((prevCount) => (prevCount > 0 ? prevCount - 1 : 0)); // Prevent negative count
  };

  const navigate = useNavigate();
  const {
    searchQuery,
    counts,
    checkInDate,
    checkOutDate,
    handleCheckInDateChange,
    handleCheckOutDateChange,
    setSearchQuery,
  } = useHotelSearch();
  const searchHotelList = () => {
    const params = {
      searchtext: searchQuery.searchtext,
      checkin: checkInDate,
      checkout: checkOutDate,
      acr: `${counts.Adults}${counts.Children}${counts.Rooms}`,
    };
    setSearchQuery(params);
    const queryParams = new URLSearchParams(params).toString();
    navigate(`/hotel-listing?${queryParams}`);
  };
  const galleryImages = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/25065b4a-dafb-4cef-aa9f-aba142e1cdc5?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
      alt: "Hotel preview image 1",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/14674aa8-3016-4016-9280-2627029a26ac?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491",
      alt: "Hotel preview image 2",
    },
  ];

  return (
    <>
      <div className={styles.bookingPage}>
        <NavbarNew />
        <div className={styles.container}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0246033cb1e556085c5790d2339934e77256c5c3c1ccb9a321040d4803c31524?placeholderIfAbsent=true&apiKey=e8c9edee91834fcc860284d6bd91f491"
            className={styles.backgroundImage}
            alt="Hotel background"
          />

          <div className={styles.bookingCard}>
            <h1 className={styles.welcomeTitle}>
              Welcome to your next Adventure!
            </h1>
            <h2 className={styles.subtitle}>
              Discover the Perfect Stay with WanderStay
            </h2>
            <div>
              <label htmlFor="location" className={styles.locationLabel}>
                Where?
              </label>
              <div className={styles.locationInput}>
                <Locate />
                <input
                  type="text"
                  id="location"
                  className={styles.locationText}
                  placeholder="Ex: New York"
                />
              </div>
              <div className={styles.dateSection}>
                <DateInput
                  label={"Check In"}
                  value={checkInDate}
                  onToggle={() => setOpenCalendar("check-in")}
                />

                <DateInput
                  label={"Check Out"}
                  value={checkOutDate}
                  onToggle={() => setOpenCalendar("check-out")}
                />
              </div>
              <div className="flex gap-[5rem]">
                <div className="w-full">
                  <div className={styles.guestLabel}>Guests</div>
                  <div className={styles.guestwrapper}>
                    <div
                      className={styles.guestbox}
                      onClick={() => increment(setGuestCount)}
                    >
                      +
                    </div>
                    <div className={styles.guestbox}>{guestCount}</div>
                    <div
                      className={styles.guestbox}
                      onClick={() => decrement(setGuestCount)}
                    >
                      -
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className={styles.guestLabel}>Room</div>
                  <div className={styles.guestwrapper}>
                    <div
                      className={styles.guestbox}
                      onClick={() => increment(setRoomCount)}
                    >
                      +
                    </div>
                    <div className={styles.guestbox}>{roomCount}</div>
                    <div
                      className={styles.guestbox}
                      onClick={() => decrement(setRoomCount)}
                    >
                      -
                    </div>
                  </div>
                </div>
              </div>

              <div
                onClick={() => searchHotelList()}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button className={styles.findButton}>FIND</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(openCalendar === "check-out" || openCalendar === "check-in") && (
        <div className="mobile-calendar-popup">
          <div className="flex items-center">
            <ChevronLeft color="red" onClick={() => setOpenCalendar("")} />
            <div
              className="text-[4rem] text-red-500"
              onClick={() => setOpenCalendar("")}
            >
              Cancel
            </div>
          </div>
          <MobileCalendar
            onFlightSelection={(data) => {
              openCalendar === "check-in"
                ? handleCheckInDateChange(data)
                : handleCheckOutDateChange(data);

              if (openCalendar) setOpenCalendar("");

              if (checkOutDate > checkInDate) {
                handleCheckOutDateChange(null);
              }
            }}
            disablePast={true}
            numberOfMonths={3}
            onClosePopup={() => setOpenCalendar("")}
            selectedDate={
              openCalendar === "check-in" ? checkInDate : checkOutDate
            }
            {...(openCalendar === "check-out" && {
              disabledFrom: checkInDate,
            })}
          />
        </div>
      )}
    </>
  );
}
