import React from "react";
import "./BestSelling.css";
import { Link } from "react-router-dom";
function BestSellingCard({ Package }) {
  return (
    <div>
      <Link
        to={`/ItinearyPage/${Package.DestinationName}/${Package.DestinationId}/${Package.PackageId}`}
      >
        <div className="bestselling-card-parent">
          <img
            className="bestselling-card-Img"
            src={Package.HeroBanners.Portait}
            alt=""
          />
          <div className="bestsllingtag">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="44"
              viewBox="0 0 123 44"
              fill="none"
            >
              <g filter="url(#filter0_d_8962_5973)">
                <path
                  d="M6.99478 1.99539L117.915 0.304882L95.4857 17.5439L118.217 34.1771L7.0658 35.608C3.94422 35.6473 5.17867 32.3797 5.26784 30.0382L5.06456 7.45275C4.9131 3.57487 3.86926 2.03867 6.99478 1.99539Z"
                  fill="url(#paint0_linear_8962_5973)"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_8962_5973"
                  x="0.777344"
                  y="0.304688"
                  width="121.441"
                  height="43.3037"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_8962_5973"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_8962_5973"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_8962_5973"
                  x1="98.1737"
                  y1="-18.995"
                  x2="25.8287"
                  y2="55.003"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFA908" />
                  <stop offset="1" stop-color="#FF4000" />
                </linearGradient>
              </defs>
            </svg>
            <p>Best Seller</p>
          </div>

          <div className="bestselling-card-details">
            <div className="bestselling-card-details-row1">
              <div className="bestselling-card-cart-days-div">
                <span>{Package.Nights}N</span> <span>{Package.Days}D</span>
              </div>
            </div>
          </div>
          <div className="bestselling_card_bottom">
            <div className="bestselling-card-details-row3">
              {Package.PackageName}
            </div>
            <div className="bestselling-card-details-row5">
              <div>
                <span className="bestselling-card-details-price">
                  INR {Package?.SellingPrice}
                </span>
                <span className="bestselling-card-details-per-person">
                  {Package.PriceType}
                </span>
              </div>
            </div>
            <div
              style={{ flexDirection: "column" }}
              className="bestselling_Card_Inclusions"
            >
              <div className="bestselling_Card_Inclusions_inner">
                {Package.InclusionList.map((item, index) => (
                  <div className="bestselling_Card_text_img" key={index}>
                    <img
                      alt=""
                      className="bestselling_card_green_tick"
                      src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/homepage/GreenTick.png"
                    />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
              <div className="explorebtns">Explore Now</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BestSellingCard;
