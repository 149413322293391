import React from "react";
import { Link, useParams } from "react-router-dom";

const AllThingsDesktop = ({ ele, id }) => {
  const { id: destinationName } = useParams();
  return (
    <div>
      {" "}
      <div
        key={id}
        className=" thingstodoCard flex flex-col  items-center gap-2"
      >
        <Link className="ActivityLayouts">
          <img src={ele?.Url} alt="" />
        </Link>
        <h1 className="limited-text font-bold">
          {ele?.ActivityName?.replace(".jpg", "").slice(0, 23)}
        </h1>
        <p>{ele.Title.slice(0, 133)}</p>
        <Link
          to={`/ExploreActivity/${destinationName}/${ele.ActivityId}`}
          className="exprlorepara"
        >
          <p>Explore Now</p>
        </Link>
      </div>
    </div>
  );
};

export default AllThingsDesktop;
