import React, { useEffect, useState } from "react";
import styles from "../PassengerDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingData,
  setJourneyProgress,
  setBaggageSeat,
  deletePassenger,
  setMealPrice,
  setBaggagePrice,
  setSeatPrice,
  setPassengerIndex,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";
import SeatMealSelection from "../../SeatBaggageSelection/SeatMealSelection";
import { Trash2 } from "lucide-react";

export const PassengerForm = () => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const [paxesNo, setPaxesNo] = useState();
  const [errorFields, setErrorFields] = useState(
    bookingData?.passengerDetails?.map(() => ({
      firstName: false,
      lastName: false,
      email: false,
      mobileNo: false,
    }))
  );

  const addPassenger = () => {
    if (bookingData?.passengerDetails.length < paxesNo) {
      const newPassenger = {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        passportNo: "",
        issueDate: null,
        expiryDate: null,
        dateOfBirth: null,
        countryCode: "India",
        mobileNo: "",
        saveDetails: false,
      };

      if (bookingData?.FlightDetails?.FlightType === "International")
        setErrorFields((prev) => [
          ...prev,
          { firstName: false, lastName: false, email: false, mobileNo: false },
        ]);
      else
        setErrorFields((prev) => [
          ...prev,
          {
            firstName: false,
            lastName: false,
            email: false,
            passportNo: false,
            mobileNo: false,
          },
        ]);

      dispatch(
        setBookingData({
          ...bookingData,
          passengerDetails: [
            ...(bookingData.passengerDetails || []),
            newPassenger,
          ],
        })
      );
    }
  };

  const formFieldChange = (e, index) => {
    const { name, type, checked, value } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    const updatedPassengerDetails = [...bookingData.passengerDetails];
    const updatedPassenger = {
      ...updatedPassengerDetails[index],
      [name]: fieldValue,
    };
    updatedPassengerDetails[index] = updatedPassenger;
    const updatedErrorFields = [...errorFields];
    updatedErrorFields[index][name] = false;
    setErrorFields(updatedErrorFields);
    dispatch(
      setBookingData({
        ...bookingData,
        passengerDetails: updatedPassengerDetails,
      })
    );
  };

  const validatePassengerDetails = () => {
    const passengerDetails = bookingData?.passengerDetails || [];
    const updatedErrorFields = passengerDetails.map((passenger) => {
      let fieldsToCheck;
      if (bookingData?.FlightDetails?.FlightType === "International")
        fieldsToCheck = [
          "firstName",
          "lastName",
          "email",
          "passportNo",
          "mobileNo",
        ];
      else fieldsToCheck = ["firstName", "lastName", "email", "mobileNo"];
      const errors = {};
      fieldsToCheck.forEach((field) => {
        errors[field] = isNullOrEmpty(passenger[field]);
      });
      return errors;
    });

    setErrorFields(updatedErrorFields);
    return updatedErrorFields.every((errors) =>
      Object.values(errors).every((hasError) => !hasError)
    );
  };

  const isNullOrEmpty = (value) => {
    if (Array.isArray(value)) return value.length === 0;
    return !value || value.trim() === "";
  };

  const handleSubmit = () => {
    const passengerDetails = bookingData?.passengerDetails || [];
    const processedHashes = new Set();
    if (!validatePassengerDetails()) return;

    for (const index in passengerDetails) {
      const passenger = passengerDetails[index];
      const passengerHash = JSON.stringify({
        passportNo: passenger?.passportNo?.trim(),
      });
      if (
        processedHashes.has(passengerHash) &&
        bookingData?.FlightDetails?.FlightType === "International"
      ) {
        alert("You have filled same passport No");
        return;
      }
      processedHashes.add(passengerHash);
    }
    if (bookingData?.passengerDetails?.length < paxesNo) {
      addPassenger();
      return;
    }

    dispatch(setJourneyProgress(2));
  };
  const deletePassengerData = (index, passenger) => {
    const baggagePrice = passenger.baggage
      ?.map((item) => parseAmount(item.price))
      ?.reduce((a, b) => a + b, 0);
    const mealPrice = passenger.meals
      ?.map((item) => item.price)
      ?.reduce((a, b) => a + b, 0);
    dispatch(setBaggagePrice({ baggagePrice: baggagePrice, Action: "remove" }));
    dispatch(setMealPrice({ mealPrice: mealPrice, Action: "remove" }));
    dispatch(
      setSeatPrice({ seatPrice: passenger?.seat?.seatPrice, Action: "remove" })
    );
    dispatch(deletePassenger({ passengerId: index }));
    setErrorFields((prevErrorFields) => {
      const updatedErrorFields = [...prevErrorFields];
      updatedErrorFields.splice(index, 1);
      return updatedErrorFields;
    });
  };
  function parseAmount(amount) {
    if (typeof amount === "string") {
      return parseInt(amount.replace(/,/g, "").replace("-", ""), 10) || 0;
    }
    return amount || 0;
  }
  useEffect(() => {
    const storedData = localStorage.getItem("flightData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData.flightPaxNChilds) {
        let paxNumber = Object.values(parsedData.flightPaxNChilds).reduce(
          (sum, value) => sum + value,
          0
        );
        setPaxesNo(paxNumber);
      }
    }
  }, []);
  return (
    <>
      {bookingData?.passengerDetails.map((passenger, index) => (
        <div className={styles.formContainer} key={index}>
          <div className={styles.nameWrapper}>
            <h2 className={styles.passengerName}>
              {passenger.firstName
                ? `${passenger?.firstName} ${passenger?.middleName} ${passenger?.lastName}`
                : `Adult ${index + 1}`}
            </h2>
            <Trash2
              style={{ cursor: "pointer" }}
              color="#fe6f61"
              onClick={() => deletePassengerData(index, passenger)}
            />
          </div>
          <div className={styles.formGrid}>
            <div className={styles.formRow}>
              <div className={styles.formColumn}>
                <div className={styles.inputGroup}>
                  <label
                    htmlFor={`firstName-${index}`}
                    className={styles.inputLabel}
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id={`firstName-${index}`}
                    name="firstName"
                    className={`${styles.inputField} ${
                      errorFields[index]?.firstName ? styles.haserror : ""
                    }`}
                    value={passenger.firstName}
                    onChange={(e) => formFieldChange(e, index)}
                    required
                  />
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={styles.inputGroup}>
                  <label
                    htmlFor={`middleName-${index}`}
                    className={styles.inputLabel}
                  >
                    Middle Name
                  </label>
                  <input
                    type="text"
                    id={`middleName-${index}`}
                    name="middleName"
                    className={styles.inputField}
                    value={passenger.middleName}
                    onChange={(e) => formFieldChange(e, index)}
                  />
                </div>
              </div>
              <div className={styles.formColumn}>
                <div className={styles.inputGroup}>
                  <label
                    htmlFor={`lastName-${index}`}
                    className={styles.inputLabel}
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id={`lastName-${index}`}
                    name="lastName"
                    className={`${styles.inputField} ${
                      errorFields[index]?.lastName ? styles.haserror : ""
                    }`}
                    value={passenger.lastName}
                    onChange={(e) => formFieldChange(e, index)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={styles.emailwrapper}>
              <label htmlFor={`email-${index}`} className={styles.inputLabel}>
                Email Address
              </label>
              <input
                type="email"
                id={`email-${index}`}
                name="email"
                className={`${styles.confirmationInput} ${
                  errorFields[index]?.email ? styles.haserror : ""
                }`}
                placeholder="Enter the Confirmation"
                value={passenger.email}
                onChange={(e) => formFieldChange(e, index)}
                required
              />
            </div>

            {bookingData.FlightDetails.FlightType === "International" && (
              <>
                <h2 className={styles.passportSection}>
                  Add Passport Information
                </h2>
                <div className={styles.passportGrid}>
                  <div className={styles.inputGroup}>
                    <label htmlFor={`passportNo-${index}`}>Passport No.</label>
                    <input
                      type="text"
                      id={`passportNo-${index}`}
                      name="passportNo"
                      className={`${styles.inputField} ${
                        errorFields[index]?.passportNo ? styles.haserror : ""
                      }`}
                      placeholder="Type here"
                      value={passenger.passportNo}
                      onChange={(e) => formFieldChange(e, index)}
                      required
                    />
                  </div>
                </div>
              </>
            )}

            <div className={styles.contactGrid}>
              <div className={styles.mobileNoDetails}>
                <label htmlFor={`countryCode-${index}`}>Country Code</label>
                <select
                  name="countryCode"
                  id={`countryCode-${index}`}
                  className={styles.inputField}
                  value={passenger.countryCode}
                  onChange={(e) => formFieldChange(e, index)}
                  required
                >
                  <option value="India">(India +91)</option>
                  <option value="Pak">(Pak +92)</option>
                </select>
              </div>
              <div style={{ width: "50%" }}>
                <label htmlFor={`mobileNo-${index}`}>Mobile no.</label>
                <input
                  type="tel"
                  name="mobileNo"
                  id={`mobileNo-${index}`}
                  className={`${styles.inputField} ${
                    errorFields[index]?.mobileNo ? styles.haserror : ""
                  }`}
                  value={passenger.mobileNo}
                  onChange={(e) => formFieldChange(e, index)}
                  required
                />
              </div>
            </div>
            <div className={styles.saveCheckbox}>
              <input
                type="checkbox"
                id={`saveDetails_${index}`}
                name="saveDetails"
                checked={bookingData?.passengerDetails[index]?.saveDetails}
                onChange={(e) => formFieldChange(e, index)}
              />
              <label htmlFor={`saveDetails_${index}`}>
                Save Passenger Details
              </label>
              <button
                type="button"
                className={styles.addPassenger}
                onClick={() => {
                  dispatch(setBaggageSeat(true));
                  dispatch(setPassengerIndex(index));
                }}
              >
                Select Seat/Meal/Baggage
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className={styles.actionButtons}>
        <div className="gap-x-[1rem] flex">
          <button
            type="button"
            className={styles.backButton}
            onClick={() => dispatch(setJourneyProgress(0))}
          >
            Back
          </button>
          <button
            type="button"
            className={`${styles.addMealSeat} ${
              bookingData?.passengerDetails?.length < paxesNo
                ? ""
                : styles.disableAddPassenger
            }`}
            onClick={addPassenger}
          >
            + Passenger
          </button>
        </div>
        <button
          type="button"
          className={styles.proceedButton}
          onClick={handleSubmit}
        >
          Proceed To Review
        </button>
        <SeatMealSelection />
      </div>
    </>
  );
};
