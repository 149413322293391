import React, { Fragment, useState } from "react";
import PaymentOptions from "./PaymentOptions";
import "./PaymentMethods.css";
import { paymentMethodsData } from "./styles";
import { ChevronLeft } from "lucide-react";
import UPIOptions from "./AllPaymentoptionScreen/UPIOptions";
import { useSelector } from "react-redux";
import CreditDebitOptions from "./AllPaymentoptionScreen/CreditDebitOptions";
const PaymentMethods = () => {
  const [isPaymentOptionOpen, setIsPaymentOptionOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const openNextStep = (option) => {
    setSelectedOption(option);
    setIsPaymentOptionOpen(true);
  };
  console.log(isPaymentOptionOpen, selectedOption);
  return (
    <>
      <div className="payment-mobile-container">
        <h2>ALL PAYMENT OPTIONS</h2>
        <div className="payment-wrapper">
          {paymentMethodsData.map((payment, index) => (
            <Fragment key={payment.id}>
              <PaymentOptions payment={payment} onPopUpopen={openNextStep} />
              {index < paymentMethodsData.length - 1 && (
                <div className="divider" />
              )}
            </Fragment>
          ))}
        </div>
      </div>
      {isPaymentOptionOpen && (
        <>
          <div className="final-payment-screen">
            <div className="backBtn-wraper">
              <ChevronLeft onClick={() => setIsPaymentOptionOpen(false)} />
              <div className="heading-wrapper">
                <h2 className="selector-title">
                  {bookingData?.FlightDetails?.Route?.Departure?.AirportCode}
                  {" - "}
                  {bookingData?.FlightDetails?.Route?.Arrival?.AirportCode}
                </h2>
                <div className="paymentSubtotal">
                  {bookingData?.FlightDetails?.Route?.Departure?.Date}
                  {" | "}
                  {bookingData?.FlightDetails?.Route?.Departure?.Time}
                </div>
              </div>
            </div>
            {selectedOption.title === "UPI Options" && (
              <UPIOptions paymentOption={selectedOption} />
            )}
            {selectedOption.title === "Credit/Debit card" && (
              <CreditDebitOptions paymentOption={selectedOption} />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default PaymentMethods;
