import React from "react";
import "../NewItinearyPage.css";
import { useSelector } from "react-redux";

const ItninearyMainContainer = () => {
  const data = useSelector((state) => state.itninearypackages.data);
  return (
    <div className="heroContainer">
      <div className="relative">
        {/* <img
          src={data?.Itineary[1]?.ActivityImg}
          className="heroContainerimg"
          alt=""
        /> */}
        <figure
          className="heroContainerimg_desktop"
          style={{
            "--background-image": `url(${data?.Itineary[1]?.ActivityImg})`,
          }}
        ></figure>
        <p className="absolute heroConatinerFont top-0 text-[2rem]">
          Experience The Best of {data?.DestinationName}
        </p>
      </div>
    </div>
  );
};

export default ItninearyMainContainer;
