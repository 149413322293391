import React, { useEffect, useState } from "react";
import FlightsHeader from "../Flights/FlightsHeader";
import "./Help.css";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavbarNew from "../Navbar/NavbarNew";
import SuppportHeader from "./SuppportHeader";
import useStickyBar from "../../Hooks/useStickyBar";
const HelpandSupport = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 150;
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: elementPosition, behavior: "smooth" });
      setActiveItem(id); // Set clicked item as active
    }
  };

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);

  const isSticky = useStickyBar(".pk");
  return (
    <div className="mainSupportpages">
      <div className={isSticky ? "sticky-navbar" : ""}>
        <NavbarNew />
      </div>
      <div className="mainSupportpage ">
        <div className="pk">
          <SuppportHeader />
        </div>
        <div className="supportsection">
          <div className="supportstickysection">
            <p
              className={activeItem === "faq" ? "active" : ""}
              onClick={() => handleScroll("faq")}
              style={{ cursor: "pointer" }}
            >
              FAQ
            </p>
            <p
              className={activeItem === "support-enquiry" ? "active" : ""}
              onClick={() => handleScroll("support-enquiry")}
              style={{ cursor: "pointer" }}
            >
              Privacy Policy
            </p>
            <p
              className={activeItem === "T&C" ? "active" : ""}
              onClick={() => handleScroll("T&C")}
              style={{ cursor: "pointer" }}
            >
              Terms & Conditions
            </p>
            <p
              className={activeItem === "CancellationPolicy" ? "active" : ""}
              onClick={() => handleScroll("CancellationPolicy")}
              style={{ cursor: "pointer" }}
            >
              Cancellation Policy
            </p>
            <p
              className={activeItem === "BookingQueries" ? "active" : ""}
              onClick={() => handleScroll("BookingQueries")}
              style={{ cursor: "pointer" }}
            >
              Booking Related Queries
            </p>
          </div>
          <div id="faq" className="content-div">
            <h1>Frequently Asked Questions</h1>
            <h2>
              Is this itinerary customisable? How can I make the changes to
              further personalise it according to my requirement?{" "}
            </h2>
            <p>
              Of course! This itinerary is completely yours and you can
              personalise it however you want your vacation to be! Just click on
              the link 'Make Changes' in this document and it will take you to
              your own Journey Routers itinerary and you can 'Edit' the trip or
              'Change' flights or hotels or activities or anything you
              absolutely wish to do!
            </p>
            <br />
            <h2>How do I know this itinerary suits my interests?</h2>
            <p>
              If you created this itinerary on our website, you would have
              noticed that we keep your interests in mind and use our smart
              algorithm to suggest the cities and activities that are most
              suitable for you. And if our travel consultants had a chat with
              you and sent this itinerary - Be rest assured they would have
              taken extra efforts to ensure this itinerary perfectly suits your
              requirements. you can always customise this itinerary further by
              clicking on the 'Make Changes' link in this doc.
            </p>
            <h2>
              What kind of Visa Assistance would be provided by Journey Routers?
            </h2>
            <p>
              Visa isn't rocket science but it can be nerve-wracking for some
              destinations. Worry not! We are here to help. We will provide
              information on the documents that need to be submitted, best
              practices for applying for a visa, how to book your appointment
              and assist you in the process throughout.
            </p>
            <h2>
              Is the itinerary cost real time? How long are these prices valid?
            </h2>
            <p>
              Yes, everything you see as a part of your itinerary is real-time
              costing and you would be able to book your vacation for those
              prices. However, the costs of flights, hotels and some activities
              are highly fluctuating and will change as per demand. Your costed
              itinerary will expire in 6 hours and you need to click on 'Update
              Cost' to view the current updated price for the itinerary.{" "}
            </p>

            <h2>
              How do I confirm and book this itinerary? When will I get my
              confirmations and vouchers?
            </h2>
            <p>
              You are vacation-ready! You can book the entire trip by paying
              just 70% cost of the total cost. All your bookings will happen
              real time and you will be able download your vouchers for flights
              and hotels from the Your Trips page in our website within 24 hours
              from the time of booking. On full payment - you will get a
              consolidated set of vouchers for the entire vacation.
            </p>
            <h2>
              How do I confirm and book this itinerary? When will I get my
              confirmations and vouchers?
            </h2>
            <p>
              .You are vacation-ready! You can book the entire trip by paying
              just 70% cost of the total cost. All your bookings will happen
              real time and you will be able download your vouchers for flights
              and hotels from the Your Trips page in our website within 24 hours
              from the time of booking. On full payment - you will get a
              consolidated set of vouchers for the entire vacation.
            </p>
            <h2>
              Do I need any documents that I need to submit to initiate booking?
            </h2>
            <p>
              No documents are required to confirm your bookings however please
              keep your passport copies handy. Kindly ensure that you type in
              the names only as per passport and double check on all the details
              you are keying in. In case you donʼt have a last name on your
              passport, feel free to give your destination experts a call so
              that they can help you out and ensure a hassle free booking
              experience.
            </p>
          </div>

          {/*  */}

          <div id="support-enquiry" className="content-div">
            <h1>Privacy Policy</h1>
            <h2>Policy Updates</h2>
            <p>
              We may update this policy occasionally. Please review this page
              periodically to stay informed about any changes.
            </p>
            <br />
            <h2>Information We Collect</h2>
            <p>
              Oe may collection Name and job title Contact detaile, incl8ding
              email and Demographic information like poJtcode and interet
              Information relevant to J8rveyJ and offer
            </p>
            <h2>How We Use Your Information</h2>
            <p>
              We use your information to improve our services and product to
              Keep internal recording Send promotional emails about new products
              or offer and I Conduct market research personalize your webste
              experience
            </p>
            <h2>Security</h2>
            <p>
              We implement physical, electronic, and managerial measures to
              protect your information from unauthorized access or disclosure.
            </p>

            <h2>Cookies</h2>
            <p>
              Cookies are small files placed on your device with your
              permission. They help us analyze website traffic and personalize
              your experience. You can choose to accept or decline cookies
              through your browser settings, though this may impact your use of
              the site.
            </p>
            <h2>Managing Your Information</h2>
            <p>
              You can opt out of direct marketing by checking the relevant box
              on forms or by contacting us at hr@journeyrouters.com. We will not
              sell or lease your information to third parties without your
              consent, except as required by law. With your permission, we may
              share information about third-party offers you might find
              interesting.
            </p>
          </div>

          <div id="T&C" className="content-div">
            <h1>Terms & Conditions</h1>

            <p>
              • Payment is to be made in Indian Rupee equivalent to Foreign
              Currency for the land part and will be deducted from your Basic
              Travel Quota as per RBI/GOI Regulations.
            </p>
            <br />
            <p>
              Journey routers shall not accept any liability or responsibility
              for any damages, loss, injury, accident, death, delay, breakdown,
              or irregularity which may occur in carrying out the tour
              arrangement. (In the event of a passenger falling ill or suffering
              any accident during the journey, all hospital expenses,
              doctor&#039;s fees, repatriation expenses, and any other charges
              incurred as a direct or indirect result of his/her
              illness/accident are the responsibility of the passenger). Journey
              routers reserves the right to claim any additional expenses due to
              delay or changes in train, airplane, bus, ship, or other service,
              weather conditions, strikes, war, quarantine, or any other cause
              whatsoever and all such losses or expenses must be borne by the
              passenger.Any damages caused to the hotel rooms / Coach during
              your stay, shall be payable by the passengers. Journey routers
              will not be liable for the same. Private minibuses and coaches are
              forbidden inside the city most of your sightseeing will be done by
              local buses and on foot. The tour manager and the driver will do
              their best to get you as close to the monuments as possible. We
              reserve the right to amend, alter, vary, or withdraw any
              particular departure, Excursion advertised. There are no refunds
              for any services i.e. meals, sightseeing, etc. not utilized on
              your tour.
            </p>
            <p>
              • For the convenience of our passengers, we will sometimes amend
              the itinerary, however, all the services will remain the same.
            </p>
            <p>
              Journey routers will not be liable for any loss of baggage, goods,
              air tickets, passport, cash, travelers&#039; cheque, or any other
              travel document or item owned by the passenger while on tour,
              however, caused.
            </p>
          </div>
          <div id="CancellationPolicy" className="content-div">
            <div>
              <h1>Cancellation Policy</h1>
              <li>Flight As Per Airline Policy</li>
              <li>Hotel - As Per the Hotel Policy</li>
              <li>Land Part - 25% Cancel Charges Before 20 Days of Travel</li>
              <li>Land Part - within 20 days of Travel No Refund</li>
              <li>
                Any Visa, TCS, Taxes, and Remittance charges paid will be Non-
                refundable
              </li>
              <li>Journey Routers Cancellation Charges- INR 2500 Per Pax.</li>
              <li>
                Reschedule Charges - INR 2,000 Per pax b Fare difference If any
                (For Flights and Land Part)
              </li>
              <li>Late Payment FEE- INR 5,000 (within allowable limits)</li>
            </div>
            <br />
            <div id="">
              <h1>Maldives cancellation Policy</h1>
              <li>
                Prior to 35 Days of Travel- Nil Cancellation Charges from Resorr
              </li>
              <li>Within 35 Day of Travel- No Refunk</li>
              <li>
                Special Case Due to Covid- Prior to 72 hrs. of Travel -if Covid
                Test Positive- Resort shall assist guests with
              </li>{" "}
              <li>rescheduling - Cost Difference if any due to peak season </li>
              <li>guests to pay difference amountu</li>
              <li>
                Any TCS, Taxes, Remittance charges paid will be Non refundabl?
              </li>
              <li>Journey Routers Cancellation Charges- INR 2500 Per Paxu</li>
              <li>
                Reschedule Charges - INR 2,000 Per pax b Fare difference If any
                (For Flights and Land Party
              </li>
              <li>Late Payment FEE- INR 5,000 ( Within allowable limits)</li>
            </div>
            <br />
            <div id="">
              <h1>Europe cancellation Policy</h1>
              <li>
                Flights Cost (Any International and domestic flight) will be as
                per Airline Policy.
              </li>
              <li>Hotel As Per Hotel Policy.</li>
              <li>Land Part 25% Cancel Charges Before 30 Days of Travel.</li>
              <li>Land Part 50% Cancel Charges Before 25 Days of Travel.</li>
              <li>Land Part - Within 25 days of Travel No Refund.</li>
              <li>
                Any Visa, TCS, Taxes, Remittance charges paid will be
                Non-refundable.
              </li>
              <li>Journey Routers Cancellation Charges- INR 10,000 Per Pax.</li>
              <li>
                Reschedule Charges INR 3,500 Per Pax b Fare difference If any
                (For Flights and Land Part)
              </li>
              <li>Late Payment FEE- INR 5,000 pp (Within allowable limits</li>
            </div>
            <br />
            <div id="BookingQueries">
              <h1>Booking Related Queries</h1>
              <h2>How do I conQirm my booking?</h2>
              <p>
                Once your payment is processed, you'll receive a booking
                confirmation via email, including your itinerary, booking
                reference number, and important travel details. If you don t
                receive this within 24 hours, please contact our support team.
              </p>
              <h2>Can I make changes to my bookingH?</h2>
              <p>
                Answer: Yes, most bookings can be modified depending on the
                terms of the service provider (airline, hotel, etc.). Please
                contact us as soon as possible to request changes. Note that
                fees may apply for certain modifications.
              </p>
              <h2>What if I need to cancel my booking?</h2>
              <p>
                Yo8 can cancel yo8r booking, tho8gh cancellation feeJ may apply
                depending on the policieJ of the airline, hotel, or to8r
                operator. Oe recommend checking our cancellation policy in yo8r
                booking confirmation and conJidering travel inJ8rance.
              </p>
              <h2>
                How can I add special requests to my booking (e.g., meal
                preferences, seat selecttion, room type)
              </h2>
              <p>
                You can add special requests during the booking process or by
                contacting our customer service team after booking. We'll do our
                best to accommodate your preferences, though they cannot be
                guaranteed.
              </p>
              <h2>Is my payment secureH</h2>
              <p>
                Answer: Yes, all transactions are processed throuNh secure,
                encrypted payment systems. We use the latest security measures
                to protect your personal and payment information.
              </p>
              <h2>What should I do if my flight is canceled or delayed?</h2>
              <p>
                If your flight is canceled or delayed, we will notify you as
                soon as possible and help you with rebooking options or refunds.
                You can also contact the airline for immediate assistance
              </p>
              <h2>Can I book travel insurance through your agency?</h2>
              <p>
                Yes, we offer travel insurance options that cover cancellations,
                delays, medical emergencies, and lost luggage. You can add
                insurance during the booking process or request it separately
              </p>
              <h2>
                What documentation do I need for my trip (e.g., passport, visa)
              </h2>
              <p>
                You will need a valid passport for international travel and may
                also require a visa depending on your destination. We recommend
                checking the visa requirements and ensuring your passport is
                valid for at least six months beyond your travel dates.
              </p>
              <h2>What happens if my hotel is overbooked when I arrive?</h2>
              <p>
                In case of overbooking, we will work with the hotel to secure a
                comparable alternative at no extra cost to you. We aim to ensure
                you have a smooth and comfortable stay
              </p>
              <h2>Can I book multi-destination trips?</h2>
              <p>
                Yes, we offer multi-destination booking options. You can
                customize your itinerary to include multiple ciies or countries
                in one trip. If you need assistance, our agents can help you
                design the perfect multi-destination journey.
              </p>
              <h2>Payments and Refunds</h2>
              <p>
                Refund policy depends on the hotels and resorts where the
                reservation has been made, and it varies from hotel to a hotel
                and factors such as the seasonality, duration between the date
                of cancellation and the check in date, etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HelpandSupport;
