import React from "react";
import styles from "./FoodCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingData,
  setMealPrice,
} from "../../../../../../features/CheckoutJourney/checkoutJourneySlice";

/*i have created here three function one is for adding the meals and another one is for increasing the meal quantity and another one is for decreasing the quantity for meals of every passenger 
in these functions we need to store the store's data in a variable so that we can change it diractly and update the store in the last */
const FoodCard = ({ title, price, imageSrc, meal }) => {
  const dispatch = useDispatch();
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const passengerIndex = useSelector(
    (state) => state.checkoutJourney.passengerIndex);
  const existingMeals = bookingData.passengerDetails[
    passengerIndex
  ].meals?.find((m) => m.id === meal.id);
  const addMealToPassenger = (meal, passengerIndex) => {
    const updatedPassengers = [...bookingData.passengerDetails];
    const passenger = updatedPassengers[passengerIndex];
    const selectedMeal = passenger?.meals?.find(
      (mealItem) => mealItem.quantity > 0
    );
    if (selectedMeal && selectedMeal.id !== meal.id) {
      alert("Remove Previous meal and select new");
      return;
    }
    const updatedMeals = [...(passenger.meals || []), { ...meal, quantity: 1 }];

    updatedPassengers[passengerIndex] = {
      ...passenger,
      meals: updatedMeals,
    };
    dispatch(
      setBookingData({
        ...bookingData,
        passengerDetails: updatedPassengers,
      })
    );
    dispatch(setMealPrice({ mealPrice: meal.price, Action: "add" }));
  };

  const decreaseMealQuantity = (passengerIndex, mealId) => {
    const updatedPassengers = [...bookingData.passengerDetails];
    const passenger = updatedPassengers[passengerIndex];
    const updatedMeals = passenger.meals
      .map((meal) =>
        meal.id === mealId ? { ...meal, quantity: meal.quantity - 1 } : meal
      )
      .filter((meal) => meal.quantity > 0);

    updatedPassengers[passengerIndex] = {
      ...passenger,
      meals: updatedMeals,
    };
    dispatch(
      setBookingData({
        ...bookingData,
        passengerDetails: updatedPassengers,
      })
    );
    dispatch(setMealPrice({ mealPrice: meal.price, Action: "remove" }));
  };

  const increaseMealQuantity = (passengerIndex, mealId) => {
    const updatedPassengers = [...bookingData.passengerDetails];
    const passenger = updatedPassengers[passengerIndex];
    const selectedMeal = passenger.meals.find(
      (mealItem) => mealItem.quantity > 0
    );
    if (selectedMeal && selectedMeal.id === meal.id) {
      alert("Each passenger can only select one meal.");
      return;
    }

    const updatedMeals = passenger.meals.map((meal) =>
      meal.id === mealId ? { ...meal, quantity: meal.quantity + 1 } : meal
    );

    updatedPassengers[passengerIndex] = {
      ...passenger,
      meals: updatedMeals,
    };
    dispatch(
      setBookingData({
        ...bookingData,
        passengerDetails: updatedPassengers,
      })
    );
    const mealItem = passenger.meals.find(
      (mealItem) => mealItem.id === meal.id
    );
    if (mealItem) {
      dispatch(setMealPrice({ mealPrice: meal.price, Action: "add" }));
    }
  };

  return (
    <>
      <div className={styles.foodCardContainer}>
        <img src={imageSrc} alt="" className={styles.foodCarMainImg} />
        <div className={styles.foodCard}>
          <div className={styles.cardContent}>
            <div className={styles.titlePrice}>
              <h3 className={styles.menuItemTitle}>{title}</h3>
              {existingMeals ? (
                <div>
                  <button
                    className={styles.decbtn}
                    onClick={() =>
                      decreaseMealQuantity(passengerIndex, meal.id)
                    }
                  >
                    -
                  </button>
                  <span className={styles.quantity}>
                    {existingMeals.quantity}
                  </span>
                  <button
                    className={styles.incbtn}
                    onClick={() =>
                      increaseMealQuantity(passengerIndex, meal.id)
                    }
                  >
                    +
                  </button>
                </div>
              ) : (
                <button
                  className={styles.addButton}
                  onClick={() => addMealToPassenger(meal, passengerIndex)}
                >
                  Add
                </button>
              )}
            </div>
            <div className={styles.priceWrapper}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/8651405268146f54c8fb53af5610242ae167eaa6712b8d4d37935b81d3333071?placeholderIfAbsent=true&apiKey=e53f01b703d141bc980da474d3888141"
                alt=""
                className={styles.currencyIcon}
              />
              <span className={styles.price}>{price}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FoodCard;
