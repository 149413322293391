import React, { useState } from "react";
import styles from "./HotelBooking.module.css";
import Calendar from "react-calendar";
import { CalendarCheck } from "lucide-react";

export function DateInput({
  translateX,
  value,
  label,
  icon,
  isOpen,
  onToggle,
  onDateChange,
}) {
  const dynamicStyle = {
    transform: `translateX(${translateX}) scale(0.9)`,
    padding: "20px",
    border: "1px solid #ff6b6b",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    zIndex: 1000,
    margin: "-98% -49%",
  };

  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    onDateChange(selectedDate.toLocaleDateString());
  };

  const handleDone = () => {
    setSelectedDate(date.toLocaleDateString());
    onToggle();
  };
  return (
    <div className={styles.dateColumn}>
      <div>{label}</div>
      <div onClick={onToggle} className={styles.dateInput}>
        <div>
          {" "}
          {value?.toLocaleDateString() || selectedDate || "Select Date"}
        </div>
        <CalendarCheck className={styles.calendarIcon} />
      </div>
      {window.size > 469 && (
        <>
          {isOpen && (
            <div className={"custom-calendar-wrapper"} style={dynamicStyle}>
              <Calendar
                onChange={handleDateChange}
                value={date}
                minDate={new Date()}
              />
              <div className="calendar-actions">
                <button
                  onClick={onToggle}
                  className="calendar-button cancel-button"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDone}
                  className="calendar-button done-button"
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
