import React, { useState } from "react";
import { FilterButton } from "./components/FilterButton";
import { RatingButton } from "./components/RatingButton";
import { FilterTag } from "./components/FilterTag";
import styles from "./styles/HotelFilters.module.css";
import PriceSlider from "./components/PriceSlider";

const cancellationOptions = ["Free cancellation", "Non refundable"];
const mealOptions = ["Breakfast included", "Meal not included"];
const ratingOptions = [" Bad", " Good", "Very Good"];
const amenitiesRow1 = ["Air Conditioned", "Free Breakfast", "Restaurant"];
const amenitiesRow2 = ["Fitness Center", "Kid Friendly", "Free Parking"];
const amenitiesRow3 = ["Pool", "Bar", "Pet Friendly"];

export const HotelFilterMobile = ({ setShowFilter }) => {
  const [selectedRatings, setSelectedRatings] = useState([]);

  const toggleRating = (rating) => {
    setSelectedRatings((prevState) =>
      prevState.includes(rating)
        ? prevState.filter((item) => item !== rating)
        : [...prevState, rating]
    );
  };

  const [selectedFilters, setSelectedFilters] = useState({
    "Cancellation policy": [],
    "Meal plan": [],
    "Guest rating": [],
    Amenities: [],
  });

  const handleFilterToggle = (category, label) => {
    setSelectedFilters((prevState) => {
      const currentSelection = prevState[category];
      const isSelected = currentSelection.includes(label);

      return {
        ...prevState,
        [category]: isSelected
          ? currentSelection.filter((item) => item !== label)
          : [...currentSelection, label],
      };
    });
  };

  const handleApply = () => {
    setShowFilter(false);
  };

  return (
    <div className={styles.hotelPage}>
      <div className={styles.divider} />

      <main className={styles.mainContent}>
        <FilterButton label="Filter" />

        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Hotel Ratings</h2>
          <div style={{ display: "flex", gap: "2rem", marginBottom: "4rem" }}>
            {[1, 2, 3, 4, 5].map((rating, index) => (
              <RatingButton
                key={index}
                data={rating}
                isSelected={selectedRatings.includes(rating)}
                onClick={toggleRating}
              />
            ))}
          </div>
        </section>

        <PriceSlider />

        <div>
          {[
            { title: "Meal plan", options: mealOptions },
            { title: "Guest rating", options: ratingOptions },
          ].map((section, index) => (
            <section key={index} className={styles.filterSection}>
              <h2 className={styles.sectionTitle}>{section.title}</h2>
              <div className={styles.tagContainer}>
                {section.options.map((option, idx) => (
                  <FilterTag
                    key={idx}
                    label={option}
                    isSelected={selectedFilters[section.title].includes(option)}
                    onClick={(label) =>
                      handleFilterToggle(section.title, label)
                    }
                  />
                ))}
              </div>
            </section>
          ))}

          <section className={styles.filterSection}>
            <h2 className={styles.sectionTitle}>Amenities</h2>
            {[amenitiesRow1, amenitiesRow2, amenitiesRow3].map((row, index) => (
              <div key={index} className={styles.tagContainer}>
                {row.map((amenity, idx) => (
                  <FilterTag
                    key={idx}
                    label={amenity}
                    isSelected={selectedFilters["Amenities"].includes(amenity)}
                    onClick={(label) => handleFilterToggle("Amenities", label)}
                  />
                ))}
              </div>
            ))}
          </section>

          <button className={styles.applyButton} onClick={handleApply}>
            Apply
          </button>
        </div>
      </main>
    </div>
  );
};
