import React, { useState } from "react";
import "./DateSelectorFlights.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

const DateSelectorFlights = ({
  onFlightSelection,
  onClosePopup,
  selectedDate,
  departDate,
}) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );

  const handleDateClick = (day) => {
    onFlightSelection(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
    );
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onFlightSelection(null);
    onClosePopup();
  };

  const handleDone = (e) => {
    e.stopPropagation();
    onClosePopup();
  };

  const handlePrevMonth = (e) => {
    e.preventDefault();
    if (
      currentMonth.getFullYear() > today.getFullYear() ||
      (currentMonth.getFullYear() === today.getFullYear() &&
        currentMonth.getMonth() > today.getMonth())
    ) {
      setCurrentMonth(
        new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1)
      );
    }
  };

  const handleNextMonth = (e) => {
    e.preventDefault();
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1)
    );
  };

  const getDaysInMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    const totalDays = new Date(year, month + 1, 0).getDate();
    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }
    for (let i = 1; i <= totalDays; i++) {
      days.push(i);
    }
    return days;
  };

  const days = getDaysInMonth(
    currentMonth.getFullYear(),
    currentMonth.getMonth()
  );

  const isBeforeToday = (day) => {
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    return date < today.setHours(0, 0, 0, 0);
  };

  const isDepartDate = (day) => {
    if (!departDate) return false;
    const date = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day
    );
    if (departDate && date <= new Date(departDate)) {
      return true;
    }
    return date.toDateString() === new Date(departDate).toDateString();
  };

  return (
    <>
      <div className="popup">
        <div className="popup-header">
          {currentMonth > new Date(today.getFullYear(), today.getMonth(), 1) ? (
            <button onClick={handlePrevMonth}>
              <ChevronLeft />
            </button>
          ) : (
            <div className="w-[24px]"></div>
          )}
          <span>
            {currentMonth.toLocaleString("default", { month: "long" })}{" "}
            {currentMonth.getFullYear()}
          </span>
          <button onClick={handleNextMonth}>
            <ChevronRight />
          </button>
        </div>
        <div className="calendar-grid">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <div key={day} className="calendar-day">
              {day}
            </div>
          ))}
          {days.map((day, index) => (
            <div
              key={index}
              className={`${day ? "calendar-date" : ""} ${
                day &&
                !isBeforeToday(day) &&
                selectedDate?.getDate() === day &&
                selectedDate?.getMonth() === currentMonth.getMonth() &&
                selectedDate?.getFullYear() === currentMonth.getFullYear()
                  ? "selected"
                  : ""
              } ${
                day && (isBeforeToday(day) || isDepartDate(day))
                  ? "disabled"
                  : ""
              }`}
              onClick={
                day && !isBeforeToday(day) && !isDepartDate(day)
                  ? () => handleDateClick(day)
                  : null
              }
            >
              {day}
            </div>
          ))}
        </div>
        <div className="popup-actions">
          <button className="cancel-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="done-btn" onClick={handleDone}>
            Done
          </button>
        </div>
      </div>
    </>
  );
};

export default DateSelectorFlights;
