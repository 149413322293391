import React from "react";

const ActivityDesktopsearch = () => {
  return (
    <div
      style={{ margin: "0 auto" }}
      className="searchbar2 thingssearch thingstodosearch w-[43rem] h-[3rem] relative right-[6px]"
    >
      <p
        style={{ fontSize: "1.4rem" }}
        className="SearchPlacholder w-full sm:text-[1.6rem]  sm:mr-[32px] "
      >
        Search Activities Here?
      </p>
      <img
        style={{ width: "1.5rem" }}
        className="searchIcon"
        src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SearchIcon.png"
        alt=""
      />
    </div>
  );
};

export default ActivityDesktopsearch;
