import React, { useState } from "react";
import styles from "./RoomServices.module.css";
import styless from "../../../RoomDetailPage/components/roomFacilities/RoomFacilities.module.css";

const initialServices = [
  "Free breakfast",
  "Non refundable",
  "Free self parking",
  "Free Wi-Fi",
  "Mineral water - Additional charge",
];

export function RoomServices() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [services, setServices] = useState(initialServices);

  const handleMoreServicesClick = () => {
    setModalOpen(true);
  };

  const handleSelectionChange = (text) => {
    setServices((prevServices) => {
      // If the item already exists, remove it; otherwise, add it
      if (prevServices.includes(text)) {
        return prevServices.filter((service) => service !== text);
      } else {
        return [...prevServices, text];
      }
    });
  };

  const Modal = ({ onClose, availableItems, onSelectionChange }) => (
    <div className={styless.modalOverlay} onClick={onClose}>
      <div
        className={styless.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styless.closeButton} onClick={onClose}>
          ×
        </button>
        <ul>
          {availableItems.map((item) => (
            <li key={item} className="flex gap-2">
              <input
                type="checkbox"
                checked={services.includes(item)}
                onChange={() => onSelectionChange(item)}
              />
              {item}
            </li>
          ))}
        </ul>
        <div onClick={onClose} className="flex cursor-pointer justify-end">
          <div className="bg-[red] text-white py-1 px-4 rounded-lg w-[5rem]">
            Done
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.servicesCard}>
      <h2 className={styles.title}>Room Services</h2>
      <div className={styles.divider} />
      <div className={styles.servicesList}>
        {services.map((service, index) => (
          <div key={`service-${index}`} className={styles.serviceItem}>
            <div className={styles.serviceBullet} />
            <span>{service}</span>
          </div>
        ))}
        <button className={styles.moreButton} onClick={handleMoreServicesClick}>
          + More Services
        </button>
      </div>

      {isModalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          availableItems={[
            "Room Service",
            "Laundry Service",
            "Free Parking",
            "Mini Bar",
            "Bar",
            "Clothing Iron",
            "Computer Facility",
          ]}
          onSelectionChange={handleSelectionChange}
        />
      )}
    </div>
  );
}
