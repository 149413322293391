import React from "react";

const Activitymobilesearch = () => {
  return (
    <div
      style={{ margin: "0 auto", width: "79rem", height: "9rem" }}
      className="searchbar2 thingssearch thingstodosearch  relative right-[6px]"
    >
      <input
        placeholder=" Search Activities Here?"
        style={{ fontSize: "3.4rem" }}
        className="SearchPlacholder w-full sm:text-[1.6rem]  sm:mr-[32px] "
      />

      <img
        style={{ width: "3.5rem" }}
        className="searchIcon"
        src="https://journeyrouters-webassets.s3.ap-south-1.amazonaws.com/SearchIcon.png"
        alt=""
      />
    </div>
  );
};

export default Activitymobilesearch;
