

import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";
import { useLocation } from "react-router-dom";

/**
 * Custom hook to provide sticky behavior to a specified element.
 * 
 * @param {string} selector - The CSS selector of the element to make sticky.
 * @returns {boolean} - Returns true if the element is sticky, otherwise false.
 */
const useStickyBar = (selector) => {
  
  // State to track whether the element is sticky or not
  const [isSticky, setIsSticky] = useState(false);

  // Custom hook to get the current window size
  const size = useWindowSize();

  // Determines if the current route includes "/ItinearyPage/"
  const isItenary = useLocation().pathname.includes("/ItinearyPage/");

  useEffect(() => {
    // Function to handle the scroll event and update sticky state
    const handleScroll = () => {
      // Get the height of the element specified by the selector
      const headerHeight = document.querySelector(selector).offsetHeight;
      
      // If the scroll position is greater than half the element's height, set sticky to true
      if (window.scrollY > headerHeight * 0.52) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Add the scroll event listener only if the window width is greater than 600px
    // and the current route is not "/ItinearyPage/"
    if ( !isItenary) {

    // if (size.width > 1000 && !isItenary) {
      window.addEventListener("scroll", handleScroll);
    } else {
      // Remove the scroll event listener if the conditions are not met
      window.removeEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts or dependencies change
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isItenary, selector, size.width]); // Dependencies: run effect when these change

  // Return the current sticky state
  return isSticky;
};

export default useStickyBar;
