import React from "react";
import "./PaymentOption.css";
import { ChevronRight } from "lucide-react";

const PaymentOptions = ({ payment, onPopUpopen }) => {
  return (
    <div className="payment-option" onClick={() => onPopUpopen(payment)}>
      <div className="option-content">
        <img
          loading="lazy"
          src={payment?.icon}
          alt={`${payment?.title} payment option icon`}
          className="option-icon"
        />
        <div className="option-details">
          <div className="option-header">
            <div className="option-title">{payment?.title}</div>
            <ChevronRight size={12} />
          </div>
          <div className="option-description">{payment?.description}</div>
        </div>
      </div>
    </div>
  );
};
export default PaymentOptions;
