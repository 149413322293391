import React, { useEffect, useState } from "react";
import styles from "./Seat.module.css";
import SeatBooking from "./SeatBooking";
import SeatMatrix from "./components/SeatMatrix";
import { useDispatch, useSelector } from "react-redux";
import {
  setBookingData,
  setSeatPrice,
} from "../../../../../features/CheckoutJourney/checkoutJourneySlice";

const Seat = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "unavailable", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
      { type: "exit", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
    [
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { empty: true },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
      { type: "Seat", color: "#FFD70000", selected: "", price: 750 },
    ],
  ]);
  const bookingData = useSelector((state) => state.checkoutJourney.bookingData);
  const passengerIndex = useSelector(
    (state) => state.checkoutJourney.passengerIndex);

  const selectingSeat = (rowIndex, seatIndex, seatNumber, seatPrice) => {
    if (bookingData?.passengerDetails[passengerIndex]?.seat?.seatPrice) {
      dispatch(
        setSeatPrice({
          seatPrice:
            bookingData?.passengerDetails[passengerIndex]?.seat.seatPrice,
          Action: "remove",
        })
      );
    }
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.map((seat) => ({ ...seat }))
      );
      for (let i = 0; i < updatedRows.length; i++) {
        for (let j = 0; j < updatedRows[i].length; j++) {
          if (updatedRows[i][j].selected === "selected") {
            updatedRows[i][j].selected = "";
          }
        }
      }
      if (updatedRows[rowIndex][seatIndex].selected !== "selected") {
        updatedRows[rowIndex][seatIndex].selected = "selected";
      }

      return updatedRows;
    });
    dispatch(updateBookingData({ rowIndex, seatIndex, seatNumber, seatPrice }));
    dispatch(setSeatPrice({ seatPrice, Action: "add" }));
  };
  const updateBookingData = (seatDetails) => (dispatch, getState) => {
    const { bookingData } = getState().checkoutJourney;
    const updatedPassengerDetails = bookingData.passengerDetails.map(
      (passenger, index) => {
        if (index === passengerIndex) {
          return {
            ...passenger,
            seat: seatDetails,
          };
        }
        return passenger;
      }
    );

    dispatch(
      setBookingData({
        ...bookingData,
        passengerDetails: updatedPassengerDetails,
      })
    );
  };
  useEffect(() => {
    bookingData.passengerDetails.map((passenger, index) => {
      if (bookingData.passengerDetails[index]?.seat) {
        if (index === passengerIndex) {
          const rowIndex = bookingData.passengerDetails[index]?.seat?.rowIndex;
          const seatIndex =
            bookingData.passengerDetails[index]?.seat?.seatIndex;
          // debugger;
          setRows((prevRows) => {
            const updatedRows = prevRows.map((row) =>
              row.map((seat) => ({ ...seat }))
            );
            for (let i = 0; i < updatedRows.length; i++) {
              for (let j = 0; j < updatedRows[i].length; j++) {
                if (updatedRows[i][j].selected === "selected") {
                  updatedRows[i][j].selected = "unavailable";
                }
              }
            }
            if (updatedRows[rowIndex][seatIndex].selected !== "selected") {
              updatedRows[rowIndex][seatIndex].selected = "selected";
            }

            return updatedRows;
          });
        } else {
          const rowIndex = bookingData.passengerDetails[index]?.seat?.rowIndex;
          const seatIndex =
            bookingData.passengerDetails[index]?.seat?.seatIndex;
          setRows((prevRows) => {
            const updatedRows = prevRows.map((row) =>
              row.map((seat) => ({ ...seat }))
            );
            for (let i = 0; i < updatedRows.length; i++) {
              for (let j = 0; j < updatedRows[i].length; j++) {
                if (updatedRows[i][j].selected === "selected") {
                  updatedRows[i][j].selected = "selected";
                }
              }
            }
            if (updatedRows[rowIndex][seatIndex].selected !== "selected") {
              updatedRows[rowIndex][seatIndex].selected = "unavailable";
            }
            return updatedRows;
          });
        }
      }
    });
    if (bookingData.passengerDetails[passengerIndex].seat) {
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.selectionSummary}>
        <SeatBooking />
      </div>
      <div className={styles.seatMap}>
        <SeatMatrix rows={rows} onSelectingSeat={selectingSeat} />
      </div>
    </div>
  );
};

export default Seat;
