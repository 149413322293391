import React, { Fragment, useEffect, useState } from "react";
import { FlightFilter } from "./FlightFilters/FlightFilter";
import styles from "./FlightListing.module.css";
import { FlightCard } from "./FligtListings/FlightCard";
import NavbarNew from "../Navbar/NavbarNew";
import Footer from "../Footer/Footer";
import FlightNav from "./FlightsNavbar/FlightNav";
import useWindowSize from "../../Hooks/useWindowSize";
import flights from "./DummyFLights.js";
import FlightListingMobile from "./[mobileRes]/FlightsSearchListing/FlightListingMobile.jsx";
import Loading from "../Loaders/Loading.jsx";

const FlightsListingPage = () => {
  const [loader, setLoader] = useState(true);
  const size = useWindowSize();
  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);
  return (
    <>
      <NavbarNew />
      {loader ? (
        <div className={styles.fullpage_loader}>
          <Loading />
        </div>
      ) : (
        <>
          {size.width > 768 ? (
            <>
              <FlightNav />
              <div className={styles.flight_container}>
                <div className={styles.flight_inner_container}>
                  <FlightFilter />
                  <div className={styles.flight_listings}>
                    {flights.map((flight, index) => (
                      <Fragment key={index}>
                        <FlightCard flight={flight} flightIndex={index} />
                      </Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <FlightListingMobile />
          )}
        </>
      )}
      <Footer />
    </>
  );
};

export default FlightsListingPage;
