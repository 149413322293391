import React, { useEffect, useState } from "react";
import "./NewNavbar.css";
import aboutImage from "../../Assets/group-about.svg";
import blogImage from "../../Assets/Blog.svg";
import phoneCallImage from "../../Assets/phone-call.svg";
import offerBoxImage from "../../Assets/offers-box.svg";
import logo from "../../Assets/Logo.png";
import xlogo from "../../Assets/xnew.svg";
import facebooklogo from "../../Assets/facebooknew.svg";
import flightlogo from "../../Assets/NavIcons/flights.svg";
import hotellogo from "../../Assets/NavIcons/hotels.svg";
import flightlogowhite from "../../Assets/NavIcons/flightswhite.svg";
import hotellogowhite from "../../Assets/NavIcons/hotelswhite.svg";
import instagramlogo from "../../Assets/instagramnew.svg";
import useWindowSize from "../../Hooks/useWindowSize";
import { ChevronDown, ChevronUp, Plane } from "lucide-react";
import { BASE_URL } from "../../utils";
import axios from "axios";
import { Link } from "react-router-dom";
import DestinationListing from "./DestinationListing";
import SidebarDrawer from "./SidebarDrawer";
import nouserImage from "../../Assets/nouserimage.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setAuthModel } from "../../features/Profile/profileSlice";
import { setAllStoreDestinationList } from "../../features/Destination/destinationSlice";
import { setQueryFetcher } from "../../features/Queryfetcher/queryFetcherModalSlice";

const NavbarNew = () => {
  const size = useWindowSize();
  // const { user } = useAuthHook();
  const user = useSelector((state) => state.profile.User);
  const [openDestinationList, setOpenDestinationList] = useState(false);
  const [destinationCategory, setDestinationCategory] = useState({
    category: "",
    value: "",
  });
  const [destinations, setDestinations] = useState([]);
  const [planHover, setPlanHover] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();

  const handleAuthModelOpen = () => dispatch(setAuthModel(true));
  const openQueryFetcher = () => dispatch(setQueryFetcher(true));

  const destinationlistingOpen = (category, value) => {
    setOpenDestinationList(true);
    setDestinationCategory({ category: category, value: value });
  };
  const destinationlistingclose = () => {
    setOpenDestinationList(false);
    setDestinationCategory({ category: "", value: "" });
  };

  const fetchDestinations = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}DataTransaction/Destination?destinationname=all`
      );
      const fetchedDestinations = response?.data;
      localStorage.setItem(
        "DestinationsLocHome",
        JSON.stringify(fetchedDestinations)
      );
      dispatch(setAllStoreDestinationList(response?.data));
      setDestinations(fetchedDestinations);
    } catch (error) {
      console.error("Failed to fetch destinations", error);
    }
  };
  const domesticDestinations = destinations?.filter(
    (dest) => dest.Type === "Domestic"
  );
  const internationalDestinations = destinations?.filter(
    (dest) => dest.Type === "International"
  );
  const navbarStickyTop = () => {
    const headerElement = document.querySelector(".navbar");
    if (size.width > 768 && headerElement) {
      const headerHeight = headerElement.offsetHeight;
      if (window.scrollY > headerHeight) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    } else return;
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const forAfterComingUserDestinationCall = () => {
    setTimeout(() => {
      fetchDestinations();
    }, 5000);
  };
  useEffect(() => {
    const cachedDestinations = localStorage.getItem("DestinationsLocHome");
    if (cachedDestinations) {
      setDestinations(JSON.parse(cachedDestinations));
      dispatch(setAllStoreDestinationList(JSON.parse(cachedDestinations)));
      forAfterComingUserDestinationCall();
    } else {
      fetchDestinations();
    }
    window.addEventListener("scroll", navbarStickyTop);
    return () => {
      window.removeEventListener("scroll", navbarStickyTop);
    };
  }, []);
  return (
    <>
      {size.width > 768 ? (
        <header className="navbar">
          <div className="navbar-top " onMouseEnter={destinationlistingclose}>
            <div className="navbar-left">
              <Link to="/">
                <img
                  src={logo}
                  alt="Journey Routers Logo"
                  className="navbar-logo h-[30px]"
                  height="30"
                  width="85"
                />
              </Link>
            </div>
            <div className="navbar-right">
              <ul className="navbar-icons">
                <li>
                  <Link to={"/about"} className="flex items-center">
                    <img
                      className="h-[30px]"
                      src={aboutImage}
                      alt=""
                      height="30"
                      width="30"
                    />{" "}
                    About Us
                  </Link>
                </li>
                <li>
                  <a className="flex" href="tel:9876543210">
                    <img
                      className="h-[20px]"
                      src={phoneCallImage}
                      alt=""
                      width="20"
                      height="20"
                    />
                    - 9876543210
                  </a>
                </li>
                <li>
                  <Link to="/Career" className="flex ">
                    Career
                  </Link>
                </li>
                <li>
                  <Link className="flex" to="/Blogs">
                    <img
                      className="h-[20px]"
                      src={blogImage}
                      alt=""
                      width="20"
                      height="20"
                    />
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="flex cursor_not_allowed">
                    <img
                      className="h-[20px]"
                      src={offerBoxImage}
                      alt=""
                      height="20"
                      width="20"
                    />
                    Offers
                  </Link>
                </li>
                <div className="flex gap-2">
                  <a
                    href="https://www.x.com/journeyrouters"
                    className="flex relative"
                    target="blank"
                  >
                    <img src={xlogo} alt="twitter" height="30" width="20" />
                  </a>
                  <a
                    href="https://www.facebook.com/JourneyRouters"
                    className="flex relative"
                    target="blank"
                  >
                    <img
                      src={facebooklogo}
                      alt="facebook"
                      height="30"
                      width="20"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/journeyrouters"
                    className="flex relative"
                    target="blank"
                  >
                    <img
                      src={instagramlogo}
                      alt="instagram"
                      height="30"
                      width="20"
                    />
                  </a>
                </div>
                <li>
                  {user ? (
                    <Link to={"/Profile/myAccount"}>
                      <div className="ProfileAvatar w-[2rem] h-[2rem] rounded-full">
                        <img
                          src={
                            user?.ProfileImage
                              ? user?.ProfileImage
                              : nouserImage
                          }
                          alt="Profile"
                        />
                      </div>
                    </Link>
                  ) : (
                    <button onClick={handleAuthModelOpen}>Login</button>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <nav className={`navbar-bottom ${isSticky ? "!fixed" : "relative"}`}>
            <ul className="navbar-links gap-x-4">
              {isSticky && (
                <li>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="Journey Routers Logo"
                      className="navbar-logo h-[30px]"
                      height="30"
                      width="85"
                    />
                  </Link>
                </li>
              )}
              <li>
                <div
                  className="flex text-[0.85rem] gap-x-1 items-center text-nowrap"
                  onMouseEnter={() =>
                    destinationlistingOpen("Category", "Honeymoon")
                  }
                >
                  Honeymoon Getaways{" "}
                  {destinationCategory.value === "Honeymoon" ? (
                    <ChevronUp width={12} />
                  ) : (
                    <ChevronDown width={12} />
                  )}
                </div>
              </li>
              <li>
                <h2
                  className="flex text-[0.85rem] gap-x-1 items-center text-nowrap"
                  onMouseEnter={() =>
                    destinationlistingOpen("Category", "Family")
                  }
                >
                  Family Packages{" "}
                  {destinationCategory.value === "Family" ? (
                    <ChevronUp width={12} />
                  ) : (
                    <ChevronDown width={12} />
                  )}
                </h2>
              </li>
              <li>
                <div
                  className="flex text-[0.85rem] gap-x-1 items-center text-nowrap"
                  onMouseEnter={() => destinationlistingOpen("OnSale", true)}
                >
                  Best Deals
                  {destinationCategory.value === true ? (
                    <ChevronUp width={12} />
                  ) : (
                    <ChevronDown width={12} />
                  )}
                </div>
              </li>
            </ul>
            <ul className="navbar-links flex items-center gap-x-4">
              <li>
                <div
                  className="flex text-[0.85rem] gap-x-1 items-center text-nowrap"
                  onMouseEnter={() =>
                    destinationlistingOpen("Type", "BestSelling")
                  }
                >
                  Best Selling Packages{" "}
                  {destinationCategory.value === "BestSelling" ? (
                    <ChevronUp width={12} />
                  ) : (
                    <ChevronDown width={12} />
                  )}
                </div>
              </li>
              <li>
                <div
                  className="flex text-[0.85rem] gap-x-1 items-center text-nowrap"
                  onMouseEnter={() =>
                    destinationlistingOpen("DestinationName", "Destinations")
                  }
                >
                  Explore Destination{" "}
                  {destinationCategory.value === "Destinations" ? (
                    <ChevronUp width={12} />
                  ) : (
                    <ChevronDown width={12} />
                  )}
                </div>
              </li>
              <Link
                to="/flights"
                className="plan-holiday-btn text-nowrap"
                onMouseEnter={() => setPlanHover("plan")}
                onMouseLeave={() => setPlanHover("")}
              >
                {planHover === "plan" ? (
                  <img src={flightlogowhite} alt="" />
                ) : (
                  <img src={flightlogo} alt="" />
                )}
                Flight
              </Link>
              <Link
                to="/hotels"
                className="plan-holiday-btn text-nowrap"
                onMouseEnter={() => setPlanHover("hotel")}
                onMouseLeave={() => setPlanHover("")}
              >
                {planHover === "hotel" ? (
                  <img src={hotellogowhite} alt="" />
                ) : (
                  <img src={hotellogo} alt="" />
                )}
                Hotel
              </Link>

              <button
                className="plan-holiday-btn text-nowrap"
                onClick={openQueryFetcher}
              >
                Plan My Holiday
              </button>
            </ul>
            <div
              className={`flex justify-center ${
                openDestinationList ? "block" : "hidden"
              } Destinationlisting_Outer_wrapper`}
            >
              <div className={`Destinationlisting_wrapper px-6 py-6`}>
                <DestinationListing
                  domesticDestinations={domesticDestinations}
                  internationalDestinations={internationalDestinations}
                  filterCategory={destinationCategory}
                  closePopupOnclick={destinationlistingclose}
                />
              </div>
              <div
                className="DestinationListingBackground"
                onMouseEnter={destinationlistingclose}
              ></div>
            </div>
          </nav>
        </header>
      ) : (
        <div
          className={`flex justify-between mobileNavbarSticky relative px-[10px]`}
        >
          <Link to={"/"} className="logo md:hidden">
            <img className="" src={logo} alt="Logo" />
          </Link>

          <div
            className=" w-[10rem] h-[15rem] md:hidden  flex justify-end items-center"
            onClick={toggleDrawer}
          >
            <svg
              className="block"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="10"
              stroke="#000"
              viewBox="0 0 16 10"
              fill="none"
            >
              <path
                d="M1.16815 9.30311H14.9021M1.16815 5.15882H14.9021M1.16815 1.01453H14.9021"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      )}

      <SidebarDrawer
        isOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        domesticDestinations={domesticDestinations}
        internationalDestinations={internationalDestinations}
      />
    </>
  );
};

export default NavbarNew;
