import React, { useState } from "react";
import { FacilitySection } from "./FacilitySection";
import styles from "./RoomFacilities.module.css";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../../../../Hooks/useWindowSize";

// Mock items for facility sections
const layoutItems = [
  { text: "Bed" },
  { text: "Window" },
  { text: "Desk" },
  { text: "Trash cans" },
];

const bathroomItems = [
  { text: "Bathrobes" },
  { text: "Towels" },
  { text: "Mirror" },
];

const comfortItems = [
  { text: "Air conditioning" },
  { text: "Fan" },
  { text: "Daily newspaper" },
];

const entertainmentItems = [
  { text: "Free Wi-Fi" },
  { text: "Telephone" },
  { text: "Satellite/Cable channels" },
];

// Modal Component
const Modal = ({
  onClose,
  availableItems,
  onSelectionChange,
  selectedItems,
}) => (
  <div className={styles.modalOverlay} onClick={onClose}>
    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
      <button className={styles.closeButton} onClick={onClose}>
        ×
      </button>
      <ul>
        {availableItems.map(({ text, category }) => (
          <li key={text} className="flex gap-2">
            <input
              type="checkbox"
              checked={selectedItems[text]?.includes(category)}
              onChange={(e) =>
                onSelectionChange(text, category, e.target.checked)
              }
            />
            {text}
          </li>
        ))}
      </ul>
      <div onClick={onClose} className="flex cursor-pointer justify-end">
        <div className="bg-[red] text-white py-1 px-4 rounded-lg w-[5rem]">
          Done
        </div>
      </div>
    </div>
  </div>
);

export const RoomFacilities = () => {
  const navigate = useNavigate();
  const size = useWindowSize();

  // State for modal and selected facilities
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});

  // Facilities data
  const initialFacilities = {
    "Room Features": layoutItems,
    "Bathroom and toiletries": bathroomItems,
    Comforts: comfortItems,
    Entertainment: entertainmentItems,
  };

  const [facilities, setFacilities] = useState(initialFacilities);

  // Handle modal open and close
  const handleMoreServicesClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    // Update facilities with selected items categorized
    setFacilities((prevFacilities) => {
      const updatedFacilities = { ...prevFacilities };

      Object.entries(selectedItems).forEach(([text, categories]) => {
        categories.forEach((category) => {
          if (!updatedFacilities[category].some((item) => item.text === text)) {
            updatedFacilities[category] = [
              ...updatedFacilities[category],
              { text },
            ];
          }
        });
      });

      return updatedFacilities;
    });

    setModalOpen(false);
  };

  // Handle selection in the modal
  const handleSelectionChange = (text, category, isChecked) => {
    setSelectedItems((prev) => {
      const updated = { ...prev };

      if (isChecked) {
        if (!updated[text]) {
          updated[text] = [];
        }
        if (!updated[text].includes(category)) {
          updated[text].push(category);
        }
      } else {
        if (updated[text]) {
          updated[text] = updated[text].filter((cat) => cat !== category);
          if (updated[text].length === 0) {
            delete updated[text];
          }
        }
      }

      return updated;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h1 className={styles.title}>Room Facilities</h1>
        <div className={styles.divider} />

        <div className={styles.facilitiesGrid}>
          <div className={styles.facilitiesRow}>
            {Object.entries(facilities).map(([title, items]) => (
              <FacilitySection key={title} title={title} items={items} />
            ))}
          </div>
        </div>

        <div className={styles.actionButtons}>
          <button
            className={styles.moreButton}
            onClick={handleMoreServicesClick}
          >
            + More Services
          </button>
          <button
            onClick={() => navigate(`/hotel-listing/rooms/payment`)}
            className={styles.paymentButton}
          >
            Proceed Payment
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <Modal
          onClose={handleCloseModal}
          availableItems={[
            { text: "Room Service", category: "Room Features" },
            { text: "Laundry Service", category: "Bathroom and toiletries" },
            { text: "Free Parking", category: "Comforts" },
            { text: "Mini Bar", category: "Entertainment" },
            { text: "Bar", category: "Entertainment" },
            { text: "Clothing Iron", category: "Room Features" },
            { text: "Computer Facility", category: "Comforts" },
          ]}
          selectedItems={selectedItems}
          onSelectionChange={handleSelectionChange}
        />
      )}
    </div>
  );
};
