import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import BlogsDetailPage from "./Components/Blogs/BlogsDetailPage";
// import ProfilePage from "./Components/Profile/ProfilePage";
import HotelMainPage from "./[Hotel]/pages/HotelMainPage";
import Gallerypage from "./Components/Gallery/Gallerypage";
import HelpandSupport from "./Components/HelpandSupport/HelpandSupport";
import useAuthHook from "./Hooks/useAuthHook";
import CartPage from "./Components/CartPage/CartPage";
import BugHunter from "./Components/BugHunter/BugHunter";
import ThingsTodoDetail from "./Components/DestinationPages/ThingsToDo/ThingsTodoDetail/ThingsTodoDetail";
import Loading from "./Components/Loaders/Loading";
import ProfilePageNew from "./Components/ProfileNew/ProfilePageNew";
// import ItinearyDesktopPage from "./Components/ItinearyPageTest/ItinearyDesktopPage";
import ItinearyMainPages from "./Components/ItinearyPageTest/ItinearyMainPages";
import CarrerPage from "./Components/CarrerPage/CarrerPage";
import AboutUsPage from "./Components/AboutUs/AboutUsPage";
import Authenticatiomodal from "./Components/Auth/Authenticatiomodal";
import {
  setDestinationName,
  setQueryFetcher,
} from "./features/Queryfetcher/queryFetcherModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";
import QueryFetcherModal from "./Components/Modals/QueryFetcherModal";
import HotelListing from "./[Hotel]/pages/HotelListing/filters/HotelListing";
import HotelDetailPage from "./[Hotel]/pages/HotelDetailPage/HotelDetailPage";
import RoomDetailPage from "./[Hotel]/pages/RoomDetailPage/RoomDetailPage";
import HotelPayment from "./[Hotel]/pages/HotelPayment/HotelPayment";
import FlightsListingPage from "./Components/Flights/FlightsListingPage";
import CheckoutJourney from "./Components/Flights/CheckoutJourney";
import { BookingReview } from "./[Hotel]/[Mobile]/bookingReview/BookingReview";
import AllThingToDo from "./Components/AllThingToDo/AllThingToDo";

const DestinationPage = lazy(() =>
  import("./Components/DestinationPages/DestinationPage")
);
const HomePage = lazy(() => import("./Components/HomePage/HomePage"));

// const ItinearyPage = lazy(() =>
//   import("./Components/ItinearyPage/ItinearyPage")
// );
const SearchResult = lazy(() =>
  import("./Components/SeachResult/SearchResult")
);
const SearchFilter = lazy(() =>
  import("./Components/SearchFilter/SearchFilter")
);
const Flights = lazy(() => import("./Components/Flights/Flights"));
const Library = lazy(() => import("./Components/Library/Library"));
// const AboutUsPage = lazy(() => import("./Components/AboutUs/AboutUsPage"));
const BlogsHomePage = lazy(() => import("./Components/Blogs/BlogsHomePage"));

function App() {
  const { user, getUser, token } = useAuthHook();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.queryfetcher.isOpenClose);
  const closeQueryFetcher = () => {
    dispatch(setQueryFetcher(false));
    // setting destination name to blank for every new modal it appears without destination if it's not clicked by package booknow button
    dispatch(setDestinationName(""));
  };

  useEffect(() => {
    if (token) {
      getUser(token);
    }
  }, [token, getUser]);

  const handleBackButton = (event) => {
    if (open) {
      closeQueryFetcher();
      event.preventDefault();
    }
  };
  useEffect(() => {
    if (open) {
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", handleBackButton);
    }
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [open]);

  return (
    <Suspense
      fallback={
        <div style={{ height: "100vh" }}>
          <Loading />
        </div>
      }
    >
      {user?.Role === "Admin" && <BugHunter />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        {user && <Route path="/Profile/*" element={<ProfilePageNew />} />}
        <Route path="/Support" element={<HelpandSupport />} />
        <Route path="/Profile" element={<ProfilePageNew />} />
        <Route path="/Destination/:id" element={<DestinationPage />} />
        <Route
          path="/ItinearyPage/:Destination/:DestinationId/:PackageId"
          element={<ItinearyMainPages />}
        />
        <Route path="/SearchFilter" element={<SearchFilter />} />
        <Route path="/SearchResult" element={<SearchResult />} />
        <Route path="/flights" element={<Flights />} />
        <Route path="/flights/flightlisting" element={<FlightsListingPage />} />
        <Route path="/flights/flightcheckout" element={<CheckoutJourney />} />
        <Route path="/Library" element={<Library />} />
        <Route path="/About" element={<AboutUsPage />} />
        <Route path="/Blogs" element={<BlogsHomePage />} />
        <Route path="/Career" element={<CarrerPage />} />
        <Route path="/Blogs/:id" element={<BlogsDetailPage />} />
        <Route
          path="/Sightseeing/:id"
          element={<BlogsDetailPage sightseeing={true} />}
        />
        <Route
          path="/ExploreActivity/:Destination/:id"
          element={<ThingsTodoDetail />}
        />
        <Route path="/ExploreActivity/:id" element={<AllThingToDo />} />
        <Route path="/searchResult" element={<SearchResult />} />
        <Route path="/gallery" element={<Gallerypage />} />
        {/* <Route path="/cart" element={<CartPage />} /> */}
        <Route path="/Support" element={<HelpandSupport />} />
        <Route path="/hotels" element={<HotelMainPage />} />
        <Route path="/hotel-listing" element={<HotelListing />} />
        <Route path="/hotel-listing/*" element={<HotelDetailPage />} />
        <Route path="/hotel-listing/rooms/*" element={<RoomDetailPage />} />
        <Route path="/hotel-listing/rooms/review" element={<BookingReview />} />

        <Route path="/hotel-listing/rooms/payment" element={<HotelPayment />} />

        <Route path="/hotels" element={<HotelMainPage />} />
        <Route path="/hotel-listing" element={<HotelListing />} />
        <Route path="/hotel-listing/*" element={<HotelDetailPage />} />
        <Route path="/hotel-listing/rooms/*" element={<RoomDetailPage />} />
        <Route path="/hotel-listing/rooms/payment" element={<HotelPayment />} />

        <Route path="/*" element={<HomePage />} />
      </Routes>
      <Authenticatiomodal />
      <Modal
        open={open}
        onClose={closeQueryFetcher}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <QueryFetcherModal />
      </Modal>
    </Suspense>
  );
}

export default App;
