import React from "react";
import { Link, useParams } from "react-router-dom";
import "./allThingstodo.css";
const AllThingsToDoMobile = ({ ele, id }) => {
  const { id: destinationName } = useParams();
  return (
    <Link to={`/ExploreActivity/${destinationName}/${ele.ActivityId}`}>
      <h1 className="limited-text text-[2.5rem] pl-10 font-bold">
        {id + 1}.{ele?.ActivityName?.replace(".jpg", "").slice(0, 23)}
      </h1>
      <div
        key={id}
        className="thingstodomobileCard flex flex-col  items-center gap-2"
      >
        <Link
          to={`/ExploreActivity/${destinationName}/${ele.ActivityId}`}
          className="ActivitymobLayouts"
        >
          <img src={ele?.Url} alt="" />
        </Link>
        <p style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          {ele.Title.slice(0, 453)}{" "}
          <div
            className="exploreparas"
            style={{
              color: "#fe6f61",
              marginLeft: "5px",
              textDecoration: "none",
            }} // Adjust spacing
          >
            Explore Now
          </div>
        </p>
      </div>
    </Link>
  );
};

export default AllThingsToDoMobile;
