import React from "react";
import HotelSearchFilter from "../HotelSearchFilter/HotelSearchFilter";
import "./HotelBanner.css";
import useWindowSize from "../../../Hooks/useWindowSize";
const HotelBanner = () => {
  const size = useWindowSize();
  return (
    <div className="hotelbannerwrapper ab">
      <HotelSearchFilter />
    </div>
  );
};

export default HotelBanner;
