// import { CircularProgress } from "@mui/material";
// import React, { useState } from "react";
// import "./QueryFetcher.css";
// import { forwardRef } from "react";
// const QueryFetcherModal = forwardRef({ onHandleLeadApi ,ref}) => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [ClientName, setClientName] = useState("");
//   const [phoneError, setPhoneError] = useState(false);
//   const [ClientNameeError, setClientNameeError] = useState(false);
//   const [loader, setLoader] = useState(null);
//   const [OTPFieldVisible, setOTPFieldVisible] = useState(false);
//   const [otp, setOtp] = useState("");

//   const validatePhoneNumber = (number) => /^[0-9]{10}$/.test(number);
//   const validateClientName = (Name) => Name.length !== 0;
//   const handlePhoneNumberChange = (e) => {
//     setPhoneError(false);
//     setPhoneNumber(e.target.value);
//   };
//   const handleClientNameChange = (e) => {
//     setClientNameeError(false);
//     setClientName(e.target.value);
//   };
//   const handleSubmit = async () => {
//     if (validatePhoneNumber(phoneNumber) && validateClientName(ClientName)) {
//       setLoader(true);
//       await onHandleLeadApi({ ClientName, phoneNumber });
//       setLoader(false);
//       clearInputs();
//     } else {
//       setPhoneError(true);
//       setClientNameeError(true);
//     }
//   };
//   function clearInputs() {
//     setPhoneNumber("");
//     setClientName("");
//   }
//   return (
//     <div ref={ref} className="queryParentDiv">
//       <div className="testtext">
//         <h3>Our Advisor is just a call away!</h3>
//         <p>Available 24x7 for your queries</p>
//         <span>
//           Fill in the details and our advisor will get in touch with you within
//           the next 24hrs.
//         </span>
//       </div>

//       {OTPFieldVisible ? (
//         <>
//           <input
//             className="ContactInput"
//             placeholder="Enter OTP"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//           />
//           <button className="query_submit_button">
//             {loader ? <CircularProgress size={20} /> : "Submit Query"}
//           </button>
//         </>
//       ) : (
//         <>
//           <input
//             className={
//               ClientNameeError ? "InputAreaerror InputArea" : "InputArea"
//             }
//             placeholder="Name"
//             value={ClientName}
//             onChange={handleClientNameChange}
//           />
//           <input
//             className={phoneError ? "InputAreaerror InputArea" : "InputArea"}
//             placeholder="Mobile Number"
//             value={phoneNumber}
//             onChange={handlePhoneNumberChange}
//           />
//           <button className="query_submit_button" onClick={handleSubmit}>
//             {loader ? <CircularProgress size={20} /> : "Submit Query"}
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default QueryFetcherModal;

import { CircularProgress } from "@mui/material";
import React, { useState, forwardRef } from "react";
import "./QueryFetcher.css";
import useHandleLeadApi from "../../Hooks/useHandleLeadApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setDestinationName,
  setQueryFetcher,
} from "../../features/Queryfetcher/queryFetcherModalSlice";
import { setPackageData } from "../../features/itnineary/itninearySlice";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const QueryFetcherModal = forwardRef((props, ref) => {
  const [phoneNumber, setPhoneNumber] = useState(
    useSelector((state) => state.profile.User?.UserId) || ""
  );
  const { LeadApi } = useHandleLeadApi();
  const [ClientName, setClientName] = useState(
    useSelector((state) => state.profile.User?.Name) || ""
  );
  const [destination, setDestination] = useState(
    useSelector((state) => state.queryfetcher.destinationName) || ""
  );
  const [departure, setDeparture] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [ClientNameeError, setClientNameeError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [OTPFieldVisible, setOTPFieldVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const { DestinationId, PackageId } = useParams();

  const validatePhoneNumber = (number) => /^[0-9]{10}$/.test(number);
  const validateClientName = (Name) => Name.length !== 0;
  const validateDestination = (Name) => Name.length !== 0;

  const handleInputChanges = (e) => {
    if (e.target.name === "phone") {
      setPhoneError(false);
      setPhoneNumber(e.target.value);
    } else if (e.target.name === "clientName") {
      setClientNameeError(false);
      setClientName(e.target.value);
    } else if (e.target.name === "destination") {
      setDestinationError(false);
      setDestination(e.target.value);
    } else if (e.target.name === "departure") {
      setDeparture(e.target.value);
    }
  };
  const queryclient = useQueryClient();
  const handleSubmit = async () => {
    if (
      validatePhoneNumber(phoneNumber) &&
      validateClientName(ClientName) &&
      validateDestination(destination)
    ) {
      setLoader(true);
      await LeadApi(ClientName, phoneNumber, destination, departure);
      setLoader(false);
      clearInputs();
      dispatch(setQueryFetcher(false));
      queryclient.invalidateQueries([
        "individualPackage",
        DestinationId,
        PackageId,
      ]);
    } else {
      if (!validatePhoneNumber(phoneNumber)) setPhoneError(true);
      if (!validateClientName(ClientName)) setClientNameeError(true);
      if (!validateClientName(destination)) setDestinationError(true);
    }
  };

  function clearInputs() {
    setPhoneNumber("");
    setClientName("");
    setDestination("");
    setDeparture("");
    dispatch(setDestinationName(""));
    // dispatch(setPackageData(null));
  }

  return (
    <div ref={ref} className="queryParentDiv" tabIndex={0}>
      <div className="testtext">
        <h3>Our Advisor is just a call away!</h3>
        <p>Available 24x7 for your queries</p>
        <span>
          Fill in the details and our advisor will get in touch with you within
          the next 24hrs.
        </span>
      </div>

      {OTPFieldVisible ? (
        <>
          <input
            className="ContactInput"
            placeholder="Enter OTP"
            value={otp}
            tabIndex={0}
            onChange={(e) => setOtp(e.target.value)}
          />
          <button className="query_submit_button" disabled={loader}>
            {loader ? <CircularProgress size={20} /> : "Submit Query"}
          </button>
        </>
      ) : (
        <>
          <input
            className={`${ClientNameeError ? "InputAreaerror" : ""} InputArea`}
            name="clientName"
            tabIndex={0}
            placeholder="Name"
            value={ClientName}
            onChange={handleInputChanges}
          />
          <input
            className={`${phoneError ? "InputAreaerror" : ""} InputArea`}
            placeholder="Mobile Number"
            name="phone"
            value={phoneNumber}
            tabIndex={0}
            onChange={handleInputChanges}
          />
          <div className="dest-depart">
            <input
              className={`${
                destinationError ? "InputAreaerror" : ""
              } InputArea`}
              placeholder="Destination Name"
              name="destination"
              value={destination}
              tabIndex={0}
              onChange={handleInputChanges}
            />
            <input
              className="InputArea"
              name="departure"
              placeholder="Departure City"
              value={departure}
              tabIndex={0}
              onChange={handleInputChanges}
            />
          </div>
          <button
            className="query_submit_button"
            onClick={handleSubmit}
            disabled={loader}
          >
            {loader ? <CircularProgress size={20} /> : "Submit Query"}
          </button>
        </>
      )}
    </div>
  );
});

export default QueryFetcherModal;
