import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useWindowSize from "../../Hooks/useWindowSize";
import ItinearyDesktopPage from "./ItinearyDesktopPage";
import ItinearyMobilePage from "./ItinearyMobilePage";
import { BASE_URL } from "../../utils";
import { setPackageData } from "../../features/itnineary/itninearySlice";
import Loading from "../Loaders/Loading";
import axios from "axios";

const fetchPackageData = async ({ queryKey }) => {
  const [_, DestinationId, PackageId] = queryKey;
  const response = await axios.get(
    `${BASE_URL}DataTransaction/GetIndividualPackage/?DestinationId=${DestinationId}&PackageId=${PackageId}`
  );
  return response.data;
};

const ItinearyMainPages = () => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const { DestinationId, PackageId } = useParams();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["individualPackage", DestinationId, PackageId],
    queryFn: fetchPackageData,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setPackageData(data));
    }
  }, [data]);

  if (isLoading) return <Loading />;
  if (isError) return <div>Error: {error.message}</div>;

  return size.width > 468 ? <ItinearyDesktopPage /> : <ItinearyMobilePage />;
};

export default ItinearyMainPages;
