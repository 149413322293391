import React from "react";

const UPIOptions = ({ paymentOption }) => {
  return (
    <div>
      <div className="enter-upi-mobile">
        <h3>
          <img src={paymentOption?.icon} alt={paymentOption.title} />
          {paymentOption?.title}
        </h3>
        <input
          type="text"
          placeholder="Enter UPI ID here"
          className="upi-input-mobile"
        />
      </div>
      <div className="footer-payment-upi">
        <span>Total: &#8377;38000</span>
        <button className="upi-pay-button">VERIFY & PAY</button>
      </div>
    </div>
  );
};

export default UPIOptions;
