import React, { useEffect, useState } from "react";
import "./allThingstodo.css";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import Footer from "../Footer/Footer";
import NavbarNew from "../Navbar/NavbarNew";
import AllThingsDesktop from "./AllThingsDesktop";
import AllThingsToDoMobile from "./AllThingsToDoMobile";
import useWindowSize from "../../Hooks/useWindowSize";
import Activitymobilesearch from "./Activitymobilesearch";
import ActivityDesktopsearch from "./ActivityDesktopsearch";
// import Navbar from "../../../Navbar/navbar";

function AllThingToDo() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSightseeing = searchParams.get("isSightseeing"); // Get query param

  let { id: destinationName } = useParams();
  const [physicalActivity, setPhysicalActivity] = useState(null);

  const fetchThingstodo = async () => {
    try {
      const url =
        isSightseeing === "true"
          ? `https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/activitysightseen?DestinationName=${destinationName}&Sightseen=true`
          : `https://2rltmjilx9.execute-api.ap-south-1.amazonaws.com/DataTransaction/activitysightseen?DestinationName=${destinationName}&PhysicalTask=true`;

      const res = await axios.get(url);
      setPhysicalActivity(res.data.Items);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  useEffect(() => {
    if (destinationName) {
      fetchThingstodo();
    }
  }, [destinationName]);
  const size = useWindowSize();
  return (
    <>
      <NavbarNew />
      <div className="thingstodofilterWrapper">
        <br />
        {size.width > 468 ? (
          <ActivityDesktopsearch />
        ) : (
          <Activitymobilesearch />
        )}

        <br style={{ color: "black" }} />
        <hr
          style={{ backgroundColor: "#8f8f8f", height: "1px", border: "none" }}
        />

        <div>
          <h1 className="thingstodomainpara">
            {`Things To Do In ${destinationName}`}: Most Popular
            &nbsp;&nbsp;&nbsp; Romantic &nbsp;&nbsp;&nbsp; Adventure
            &nbsp;&nbsp;&nbsp; Museum
          </h1>
        </div>
      </div>
      <div className="mt-[4rem]  flex-wrap flex gap-[2rem] justify-center">
        {physicalActivity?.map((ele, id) =>
          size.width > 468 ? (
            <AllThingsDesktop id={id} ele={ele} />
          ) : (
            <AllThingsToDoMobile ele={ele} id={id} />
          )
        )}
      </div>

      <Footer />
    </>
  );
}

export default AllThingToDo;
